<template>
  <div class="search-widget">
    <v-search
      :key="lang + brand_slug + key_up"
      :show-tabs="showTabs"
      brand-theme="light"
      :custom-lang="lang"
      :custom-class="classes_string"
      :brand-slug="brand_slug"
      :venue-select="venue_select"
      :lane-select="lane_select"
      :adults-select="adults_select"
      :children-select="children_select"
      :infants-select="infants_select"
      :senior-select="senior_select"
      :active-tab="activeTab"
      :hide-tabs="hideTabs"
      :date-start="dateStart"
      :date-end="dateEnd"
      :button-text="buttonText"
      :use-12h="set_12_hr"
      :emit-search="emit_search"
      :loading-data="loading_data"
      :fetch-images="fetch_images"
      :hide-venue-select="hide_venue_select"
    ></v-search>
  </div>
</template>
<script>
// v-search is a custom element
// the js needed is in index.html
// isCustomElement is set in vue.config.js

export default {
  name: "SearchWidget",
  components: {},
  props: {
    brand_slug: String,
    activeTab: String,
    showTabs: String,
    hideTabs: String,
    dateStart: String,
    dateEnd: String,
    buttonText: String,
    lang: String,
    venue_select: String,
    lane_select: String,
    adults_select: String,
    children_select: String,
    infants_select: String,
    senior_select: String,
    custom_class: String,
    emit_search: String,
    loading_data: String,
    fetch_images: String,
    hide_venue_select: String,
  },
  data() {
    return {
      key_up: 0,
      set_12_hr: false,
      classes_string: "",
    };
  },
  computed: {},
  methods: {
    set12hr() {
      this.set_12_hr = "false";
      setTimeout(() => {
        if (this.brand_slug === "RDU" || this.brand_slug === "YHM" || this.brand_slug === "OKC" || this.brand_slug === "PV1") {
          this.set_12_hr = "true";
          this.key_up++;
        }
      }, 100);
    },
  },
  mounted() {
    this.classes_string = `tabs-left large-strings ${
      this.custom_class ? this.custom_class : ""
    }`;
    if (this.brand_slug === "NWI" && !this.emit_search) {
      this.classes_string = ``;
    }
    if (this.brand_slug === "SV1") {
      this.changed_buttonText = "View Rates";
    }
    this.key_up++;

    this.set12hr();
  },
  updated() {},
};
</script>

<style scoped lang="scss">
.search-widget {
  position: relative;
  // max-width: 1260px;
  margin: 0 auto;
  z-index: 9999;

  @media screen and (max-width: 1260px) {
    padding: 0 1rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0rem 0rem 0 0rem;
  }
}
</style>
