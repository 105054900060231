<template>
  <div v-if="false">
  <transition name="fade">
    <div v-if="brandSlug === 'RDU'" class="cookie-panel-wrapper" v-show="notcookie">
      <div class="cookie-panel">
        <h3>
          Our use of cookies
        </h3>
        <p>
          We use necessary cookies to make our site work. We'd also like to set analytics cookies that help us make improvements by measuring how you use the site. These will be set only if you accept.
        </p>
        <p>
          For more details about the cookies we use,
          <a :href="cookiepolicy">see our Cookies page.</a>
        </p>
        <div target="_blank" class="cookie-buttons">
          <button @click="accept">Accept all cookies</button>
          <button @click="decline">Reject all cookies</button>
        </div>
      </div>
    </div>
  </transition>
</div>
</template>
<script>
export default {
  name: "Cookies",
  props: {
    brandSlug: undefined,
  },
  components: {},
  data() {
    return {
      notcookie: false,
      cookiepolicy: "https://www.rdu.com/privacy/",
    };
  },
  methods: {
    accept() {
      this.notcookie = false;
      this.setCookie("cookie_choice_made", "accept", 365);
      // window.consentGranted();
      let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", "https://pipeline.rdu.com/index.js");
        scriptEle.setAttribute(
          "data-proxy",
          "https://pipeline.rdu.com"
        );
        document.body.appendChild(scriptEle);
        scriptEle.addEventListener("load", () => {
          //console.log("Tinybird script loaded");
        });

        scriptEle.addEventListener("error", (ev) => {
          //console.log("Error on loading tinybird script", ev);
          console.log('ev',ev);
        });
      // fire off the consent given event
    },
    decline() {
      this.notcookie = false;
      this.setCookie("cookie_choice_made", "decline", 365);
      //window.consentDenied();
      // fire off the consent denied event
    },
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
  mounted() {
    let cookieState = this.getCookie("cookie_choice_made");
    if (cookieState) {
      if (cookieState === "accept") {
        this.accept();
      } else {
        this.decline();
      }
    } else {
      this.notcookie = true;
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.cookie-panel-wrapper {
  background: #00000040;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 9999999999999;
  pointer-events: none;
}

.cookie-panel {
  pointer-events: all;
  background: #fff;
  position: fixed;
  // bottom: 0px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 2rem);
  height: auto;
  max-width: 70ch;
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  @media screen and (max-width: 576px) {
    padding: 1rem;
  }
  color: #666666;
  font-family: $font-family-medium;
  text-align: center;
  z-index: 9999999999999;
  box-shadow: 0 0px 20px 0 rgb(0 0 0 / 20%);
  a {
    color: $bc-4;
  }

  .cookie-buttons {
    button {
      background: $bc-2;
      color: #fff;
      padding: 16px 32px;
      border: 0px solid #fff;
      border-radius: 4px;
      margin: 5px;
      cursor: pointer;
      transition: 0.2s ease-in-out;

      &:hover {
        background: mix($bc-2, #000, 70%);
      }
    }
  }
}
</style>
